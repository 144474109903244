.node {
	h1 {
		margin-bottom: 2px;

		.node-n-pods-badge {
			font-size: 0.84rem;
		}
	}

	.pods {
		margin-top: 0.4em;
	}
}