.pod {
	margin: 0px 0px 4px 0px;
	animation: show-pod 1s;
	clear: both;
	background: rgba(0,0,0,0.1);
	border-radius: 5px;
	padding: 0px 3px;

	.pod-name {
		white-space: nowrap;

		.pod-name-suffix {
			color: #999;
			font-size: 0.8em;
		}
	}

	&.completed {
		color: grey;
	}

	.custom-switch {
		display: inline-block;
	}

	.pod-status-badge {
		opacity: 0.8;
	}

	.pod-n-containers-badge {
		float: left;
	}

	&.hover-pod-set-name {
		.pod-name {
			padding-top: 2px;
			padding-bottom: 2px;
			margin-left: -3px;
			margin-right: -3px;
			padding-left: 3px;
			padding-right: 3px;
			background: #3F3F3F;
			border-radius: 3px;
			color: white;
		}

		&.pod-set-has-multiple-pods {
			.pod-name {
				background: royalblue;
			}
		}
	}

	.pod-second-line {
		height: 16px;
		.pod-resources {
			display: inline-block;
			margin-left: 8px;
			width: calc(100% - 36px); // 36px is a magic number. I have no idea where it comes from. //

			.progress {
				background: transparent;
				height: 16px;

				&:nth-child(2) {
					position: relative;
					top: -16px;
					margin-bottom: -16px;
					pointer-events: none; // To make the tooltip from the other progress bar, that is underneath this one, reachable. //

					.progress-bar {
						pointer-events: all; // ^... unless the user hovers over the filled part of *this* progress bar. //
					}
				}
			}
		}
	}
}

@keyframes show-pod {
	0% {
		overflow: hidden;
		max-height: 0px;
	}

	60% {
		max-height: 1.6em;
	}

	98% {
		max-height: 3em;
	}

	99% {
		max-height: 100vh;
		overflow: hidden;
	}

	100% {
		overflow: unset;
		max-height: unset;
	}
}